.bireport_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: #EEEEEE;
}

.bireport_mainDiv {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
    height: 86vh;
    overflow-y: auto;
    width: 100%;
    margin: 12px;
    background-color: white;
    border-radius: 3px;
}

/* .org-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.org-btns {
    width: 100%;
    display: flex;
    /* grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); */
    gap: 15px;
    justify-content: center;
    align-items: center;
}


.users-login-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 190px;
    padding:0rem
}

.user-login-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 6px;
    border: 4px solid #EEEEEE;
    padding: 6px;
}

.redirect-btn {
    width: 50%;
    background-color: rgba(1, 99, 247, 0.9);
    border-radius: 8px;
    text-align: center;
    padding: 7px;
    color: white;
    outline: none;
    border: none;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.redirect-btn:hover {
    background-color: rgba(1, 80, 230, 1);
    transform: translateY(-3px);
}

.redirect-btn:active {
    background-color: rgba(1, 99, 247, 0.8);
    transform: translateY(0);
}