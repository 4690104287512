.Login-container {
    /* background-image: url("https://uat.refresh.controlfms.com/resources/assets/img/login/refresh/video-login-bg-img.webp"); */
    background-image: url("../Login/Login.jpg");
    height: 100vh;
    width: 100%;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.users-login-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.user-login-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 6px;
    gap: 1rem;
}

.redirect-btn {
    width: 80%;
    background-color: rgba(1, 99, 247, 0.9);
    border-radius: 8px;
    text-align: center;
    padding: 14px 20px;
    color: white;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.redirect-btn:hover {
    background-color: rgba(1, 80, 230, 1);
    transform: translateY(-3px);
}
.redirect-btn:hover:disabled {
    /* background-color: rgba(1, 80, 230, 1); */
    background-color: rgba(1, 99, 247, 0.9);

    transform:none
}

.redirect-btn:active {
    background-color: rgba(1, 99, 247, 0.8);
    transform: translateY(0);
}
